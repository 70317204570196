<template>
  <div class="increase">
    <div class="form">
      <div class="tit">
        您正在申请平安核卡版信用卡<br>
        请填写申请信息
      </div>

      <van-form ref="form" label-width="68">
        <van-field
          v-model="form.realName"
          label="姓名"
          label-class="label"
          placeholder="请输入申请人的真实姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
          v-model="form.idCard"
          label="身份证"
          label-class="label"
          placeholder="请输入申请人的身份证号"
          :rules="[
            { required: true, message: '请填写身份证' },
            { pattern: idCardPattern, message: '身份证无效' }
          ]"
        />
        <van-field
          v-model="form.phone"
          label="手机号"
          label-class="label"
          placeholder="请输入申请人的手机号"
          :rules="[
            { required: true, message: '请填写手机号' },
            { pattern: phonePattern, message: '手机号无效' }
          ]"
        />
        <van-field
          v-model="captcha"
          label-class="label"
          clearable
          label="验证码"
          placeholder="请注意查收短信"
          :rules="[{ required: true, message: '请填写验证码' }]">
          <template #button>
            <sms-button :phone="form.phone" :getCode="getVCode" packageName="com.qdrsd.boxassistand" />
          </template>
        </van-field>
      </van-form>
    </div>

    <div class="footer">
      <van-button color="#2E5BFD" block class="btn" @click="onSubmit">提交申请</van-button>
    </div>

    <prompt
      title="信息确认"
      cancel-btn-text="再次修改"
      confirm-btn-text="确定"
      v-model="applyConfirm"
      @confirm="onApply">
      <div class="msg">信息填写错误会影响后续业务进行，请再次确认哦！</div>
      <div class="info">
        <div class="line">申请人：{{ form.realName }}</div>
        <div class="line">手机号：{{ form.phone }}</div>
        <div class="line">身份证：{{ form.idCard }}</div>
        <div class="line flex">
          <div class="flex-1">地&emsp;区：{{ locationError ? '定位失败' : address.city }}</div>
          <div class="blue" style="font-weight:normal" @click="getCity">重新定位</div>
        </div>
      </div>
    </prompt>
  </div>
</template>

<script>
import SmsButton from '@/components/smsbutton'
import { idCardPattern, phonePattern } from '@/utils/validate'
import map from '@/utils/baduMap'

export default {
  name: 'Increase',
  components: {
    SmsButton,
  },
  data() {
    return {
      form: {
        realName: '',
        phone: '',
        idCard: '',
      },
      captcha: '',
      applyConfirm: false,
      idCardPattern,
      phonePattern,
      locationError: false,
      address: {}
    }
  },
  async created () {
    const options = await this.getCity()
    this.address = options.address
  },
  methods: {
    getVCode(code) {
      console.log('%c 🥚 code: ', 'font-size:20px;background-color: #2EAFB0;color:#fff;', code);
    },
    onSubmit() {
      this.$refs.form.validate().then(() => {
        this.applyConfirm = true
      })
    },
    async onApply() {
      const { bankId, recommendCode } = this.$route.query
      // const bankId = '6'
      // const recommendCode = 'V251865'
      const { province, city } = this.address
      const res = await this.$api.increase.card_order_create({
        applyer: this.form,
        captcha: this.captcha,
        location: `${province}#${city}`,

        bankId,
        recommendCode,
      })
      console.log('%c 🍝 res: ', 'font-size:20px;background-color: #E41A6A;color:#fff;', res);
      this.$toast('申请成功')
    },
    async getCity() {
      const BMap = await map.init()
      const locationCity = new BMap.Geolocation()

      return new Promise((resolve, reject) => {
        locationCity.getCurrentPosition(
          options => {
            this.locationError = false
            resolve(options)
          },
          e => {
            this.locationError = true
            console.log('e============', e);
            reject(e)
          },
          { provider: "baidu" }
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.increase {
  padding: toVw(106px) 12px 76px;
  background: url(./images/bg.png) no-repeat center top/100% auto;
}

.form {
  overflow: hidden;
  border-radius: 6px;
  background-color: #fff;

  .tit {
    padding: 14px 16px;
    color: #132662;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }

  ::v-deep {
    .label {
      color: #313235;
    }

    .van-field {
      min-height: 50px;
    }
  }
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 0 16px;
  width: 100%;
  height: 56px;
  z-index: 1;
  box-sizing: border-box;
  background-color: #fff;

  .btn {
    margin-top: 6px;
    height: 44px;
    border-radius: 6px;
    font-size: 16px;
  }
}

.msg {
  color: #969799;
  text-align: center;
  font-size: 14px;
}
.info {
  margin-top: 18px;

  .line {
    margin-bottom: 12px;
    text-align: left;
    font-weight: bold;
    color: #353536;
  }
}
</style>
